const resource = {
    en: {
        heading: 'Error 404',
        message: 'The resource could not be found.',
        back_to_home: 'Back to home',
    },
    de: {
        heading: 'Fehler 404',
        message: 'Die angeforderte Ressource konnte nicht gefunden werden',
        back_to_home: 'Zurück zur Homepage',
    }
}

export default resource;