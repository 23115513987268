<template>
    <div class="authentication-card">
        <h1 class="authentication-heading" v-html="resource[lang].heading" />
        <p class="authentication-text" v-html="resource[lang].message" />
        <Button stretch rounded submit
            class="authentication-button" 
            color="primary" 
            :label="resource[lang].back_to_home" 
            @click="$router.push('/')"
        />
    </div>
</template>

<script>
import resource from '@/assets/resources/not-found-resource'

import Button from '@/components/basic/Button'

export default {
    components: {
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
}
</script>